@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Text:wght@400;700&display=swap');
html, body {
  max-width: 100%;
  overflow-x: hidden;
}
.rain-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2;
}
.modal-text-content h2 {
  font-family: 'Wix Madefor Text', sans-serif;
  font-size:  45px !important;      /* Set size for prominence */
  font-weight: 2;     /* Bold for emphasis */
  text-transform: uppercase; /* Make it all uppercase */
  color: #333;          /* Darker color for contrast */
  text-align: center;   /* Center alignment */
  margin-bottom: 20px;  /* Add space below the header */
}

.header-with-line::after {
  content: "";
  display: block;
  width: 100%; /* Full width of the header */
  height: 2px; /* Thickness of the line */
  background-color: #cbc4c4; /* Adjust color to match your theme */
  margin-top: 5px; /* Space between text and the line */
}
/* Modal container */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
  animation: fadeIn 0.3s ease-in-out; /* Fade-in animation */
  z-index: 1000; /* Higher z-index to ensure modal is on top */

}

/* Modal content */
.modal-content {
  background: #fff; /* White background for content */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden; /* Ensures rounded corners are applied to all content */
  display: flex;
  flex-direction: column; /* Stack header, body, and footer vertically */
  width: 80%;
  height: 100%;
  max-height: 700px;
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow for subtle depth */
}

/* Modal body */
.modal-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  height: 100%; /* Ensure modal-body also takes full height of modal-content */

}

/* Modal text content */
.modal-text-content {
  flex: 1;
  padding-right: 2px;
  font-family: 'Montserrat';
  padding-bottom: 40px;
}
.modal-text strong {
  font-size: 50px;  /* Increase the font size */
  font-weight: 700; /* Make it bold */
  color: #c79288;   /* Add a distinct color */
}
.modal-text-content .modal-text {
  font-size: 18px;
  line-height: 1.7;
  padding: 10px 20px;
  padding-top: 5px;
}

.modal-text-content .modal-text-1 {
  font-size: 16px;
  line-height: 1.7;
  padding: 10px 20px;
}

.modal-text-content .modal-text-2 {
  font-size: 16px;
  line-height: 1.7;
  padding: 10px 20px;
}


.modal-image {
  flex: 1;
  max-width: 50%; /* Ensure the image container takes up only half of the modal width */
  height: 100%; /* Make the image container fill the full height of the modal */
  opacity: 85%;
}

.modal-image img {
  width: 100%;
  height: 100%; /* Ensure the image fills the container fully */
  object-fit: cover; /* Ensure the image maintains its aspect ratio and fills the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Subtle shadow around the image */
}

/* Modal footer */
.modal-footer {
  text-align: center;
  padding: 10px 20px;
  background: #f1f1f1; /* Light gray background */
}

.close-button {
  background-color: #c79288; /* Button color */
  color: #fff; /* Text color */
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease; /* Smooth transition */
}

.close-button:hover {
  background-color: #a87c72; /* Darker shade on hover */
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


/* src/components/HomePage.css */
h1 {
    font-family: 'Cormorant Garamond', serif;
    font-weight: 300; /* Light weight */
    font-size: 100px; /* Font size */
    line-height: 1.2; /* Adjust line height as needed */
    color: #8f6161; /* Change color if needed */
  }

.welcome-image-container {
    position: relative;
    overflow: hidden;
}

.welcome-image {
    width: 50%;
    float: right;
    transition: transform 0.3s ease-out; /* Smooth transition for zoom */
}

.zoomed {
    transform: scale(1.2); /* Scale up the image */
}
.welcome-text {
  position: absolute;
  top: 30%;
  left: 20%;
  transform: translate(-50%, -50%);
  color: #794949; /* Change color as needed */
  text-align: center;
  z-index: 1; /* Ensure text is above the image */
  transition: opacity 0.5s ease-out; /* Smooth transition for text visibility */
}
@media (max-width: 767px) {
  h1 {
    color:black;
  }
  .welcome-text {
    left: 35%; /* Adjust this percentage to move the text more to the right */
    text-align: left; /* Align text to the right */
    color: black;
  }
}

.hero-section {
    background-color: #dfd9d9 !important; /* Background color */
    padding: 60px 20px; /* Increased padding for a larger section */
    text-align: center;
    margin: 0; /* Remove margin to ensure full-width appearance */
    border-radius: 8px; /* Rounded corners for a softer look */
    width: 100vw; /* Full width of the viewport */
    box-sizing: border-box; /* Include padding and border in total width */
    height: 25vh; /* Height of 30% of the viewport height */
    display: flex; /* Flexbox for centering content */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    position: relative; /* Position relative to document flow */
    left: 0; /* Align to the left edge */
    top: 0; /* Align to the top edge */
    z-index: 1; /* Ensure it’s above other content if needed */
  }
  
  .hero-text {
    font-family: 'Montserrat', sans-serif; /* Font similar to the image */
    font-size: 48px; /* Adjust the size */
    font-weight: 0; /* Regular weight for a sleek look */
    color: #000000;
    display: inline-block;
    position: relative;
    margin: 0 auto;
  }
  
  .hero-text::after {
    content: '';
    display: block;
    width: 40px; /* Width of the line */
    height: 2px; /* Thickness of the line */
    background-color: #000; /* Line color */
    margin: 10px auto 0; /* Adjust spacing between text and line */
  }
  
  /* Responsive adjustments for hero section */
  @media (max-width: 768px) {
    .hero-section {
      padding: 30px 10px; /* Adjust padding for tablets */
    }
  
    .hero-text {
      font-size: 1.2rem; /* Adjust font size for tablets */
      padding: 0 10px; /* Add padding to ensure text doesn't touch the edges */
    }
  }
  
  @media (max-width: 480px) {
    .hero-section {
      padding: 20px 5px; /* Adjust padding for mobile phones */
    }
  
    .hero-text {
      font-size: 1rem; /* Further adjust font size for mobile phones */
      padding: 0 5px; /* Add padding to ensure text doesn't touch the edges */
    }
  }
  
  .hero-section {
  background-color: #fff8f8 !important;
  padding: 60px 20px;
  text-align: center;
  margin: 0;
  border-radius: 8px;
  width: 100vw;
  box-sizing: border-box;
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 0;
  top: 0;
}


  .grid-link {
    text-decoration: none; /* Remove underline from links */
    display: block; /* Make the link cover the grid item */
  }
  
  /* .grid-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    text-align: center;
    justify-content: center;
    align-items: center;
    
  } */
  .grid-section {
    display: flex;
    justify-content: space-around;
    gap: 50px;
    
    text-align: center;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  
  .grid-item {
    position: relative;
    width: 400px; /* Adjust width */
    height: 400px; /* Adjust height */
    overflow: hidden;
  }
  
  .grid-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.7); /* Darken image slightly */
  }
  
  .grid-text-container {
    position: absolute;
    bottom: 20px;
    left: 0px;
    right: 20px;
    color: white;
    font-family: 'Montserrat', sans-serif !important; /* Font similar to the image */
    font-weight: normal;
        text-align: left;
  }
  
  .grid-title {
    color: white !important;
    font-family: 'Montserrat', sans-serif !important; /* Font similar to the image */

  }
  .grid-text {
    position: absolute;
    bottom: 10px; /* Adjust the distance from the bottom */
    left: 0;
    right: 0;
    font-size: 24px; /* Adjust font size as needed */
    font-family: 'Montserrat', sans-serif !important; /* Font similar to the image */
    font-weight: normal;
    color: white !important; /* Change text color to contrast with the image */
    padding: 10px;
  }
  

  .view-service {
    color: white !important;
    font-weight: 0px;
    
  }
  .grid-title {
    font-size: 20px; /* Adjust font size */
    margin: 0;
  }
  
  .line {
    width: 50px; /* Adjust width */
    height: 2px;
    background-color: white;
    margin: 10px 0;
  }
  
  .view-service {
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .grid-item p {
    font-size: 1.1rem; /* Increase font size */
    font-weight: bold; /* Make text bold */
    color: #333; /* Dark text color for contrast */
    margin-top: 10px; /* Space between image and text */
    text-transform: uppercase; /* Capitalize text */
    letter-spacing: 1px; /* Slightly increase letter spacing */
    font-family: 'Cormorant Garamond', serif;
    cursor: pointer; /* Ensure cursor is pointer on hover */
    padding: 10px;

  }

  .grid-item:hover {
    transform: scale(1.05); /* Slightly enlarge the item on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
    cursor: pointer; /* Ensure cursor is pointer on hover */

  }
  
  
  .social-image-container {
    position: relative; /* Make this element the reference for absolute positioning */
    width: 100vw; /* Full viewport width */
    overflow: hidden; /* Hide overflow to ensure content fits */
    margin: 0; /* Remove margin to fit the viewport */
    padding: 0; /* Remove padding to fit the viewport */
  }

  .overlay-links {
    margin-top: 10px; /* Space between the text and the links */
  }
  
  .overlay-links a {
    color: #fff; /* Link color */
    text-decoration: none; /* Remove underline */
    margin: 0 10px; /* Space between links */
    display: inline-block; /* Ensure links are displayed inline */
  }
  
  .overlay-links a img {
    width: 40px; /* Set width for icons */
    height: auto; /* Maintain aspect ratio */
  }
  .overlay-links a .facebook-icon {
    width: 44px; /* Adjust to the size you want */
    height: auto; /* Maintain aspect ratio */
}

  .overlay-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-family: 'Poppins', sans-serif; /* Modern font */
    font-size: 1.2rem; /* Font size for text */
    font-weight: 600;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5); /* Background color with opacity */
    padding: 100px 230px;
    border: 5px solid white; /* Increase border thickness */
    border-radius: 10px; /* Rounded corners for a softer look */
    max-width: 100%;
    
  }
  .social-image-container {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: space-between; /* Space out children */
    height: 120vh; /* Full viewport height */
    position: relative; /* Position relative for overlay */
}
/* Follow button */
.follow-button-container {
  text-align: center; /* Center the button */
  margin-top: 15px;
}

.follow-button {
  background-color: #6b6666;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem; /* Increase size for better visibility */
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.follow-button:hover {
  background-color: #b5756b;
}

.contact-form-container {
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  width: 120%; /* Full width of the container */
  max-width: 120%; /* Prevents container from exceeding screen size */
  box-sizing: border-box;
  position: relative;
  text-align: center;
  right: 10%;
}

.contact-form-container h2 {
    margin-bottom: 15px;
    font-size: 24px;
    color: #333;
}

.contact-form-container .form-group {
    margin-bottom: 15px;
}

.contact-form-container .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.contact-form-container .form-group input,
.contact-form-container .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.contact-form-container .submit-button {
    background-color: #000000;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.contact-form-container .submit-button:hover {
    background-color: #a77a6c;
}

.social-image {
  width: 100%; /* Fit within the container */
  height: auto; /* Maintain aspect ratio */
  margin: 0; 
  padding: 0; 
  display: block;
  filter: brightness(0.9);
}

/* Follow Button */
.follow-button-container {
  margin-top: 15px;
}


.follow-button {
  background-color: #6b6666; /* Button background color */
  color: white; /* Button text color */
  border: none;
  padding: 5px 10px;
  font-size: 0.7rem; /* Increase the font size slightly */
  font-family: 'Poppins', sans-serif; /* Use Poppins font */
  font-weight: 200; /* Add weight for bold effect */
  cursor: pointer;
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.follow-button:hover {
  background-color: #b5756b; /* Darker shade on hover */
}


  /* General styles for smaller devices */
@media (max-width: 768px) {
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    margin-top: 130px;
    width: 100%;
    height: 70%;
    background-color: transparent;
    animation: fadeIn 0.3s ease-in-out; /* Fade-in animation */
    z-index: 1000; /* Higher z-index to ensure modal is on top */
  
  }

  .modal-image img {
    width: 100%;
    height: auto; /* Maintain aspect ratio */  
    float: right;
    height: 100%; /* Ensure the image fills the container fully */
    object-fit: cover; /* Ensure the image maintains its aspect ratio and fills the container */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Subtle shadow around the image */
  }
  .modal-text-content h2 {
    font-family: 'Wix Madefor Text', sans-serif;
    font-size: 13px !important;      /* Set size for prominence */
    font-weight: 2;     /* Bold for emphasis */
    text-transform: uppercase; /* Make it all uppercase */
    color: #333;          /* Darker color for contrast */
    text-align: center;   /* Center alignment */
    margin-bottom: 20px;  /* Add space below the header */
  }
  .welcome-image {
    width: 100%; /* Full width on tablets and smaller screens */
    opacity: 80%;
    margin-top: 10%;
  }

  .grid-section {
    flex-direction: column; /* Stack the grid items vertically */
    gap: 20px; /* Reduce the gap for smaller screens */
  }

  .grid-item {
    width: 100%; /* Reduce grid item width for smaller screens */
    height: auto; /* Let height adapt to content */
  }

  .hero-section {
    padding: 40px 20px; /* Adjust padding for mobile screens */
    height: 40vh; /* Adjust height for mobile */
  }

  .hero-text {
    font-size: 2rem; /* Reduce font size for mobile */
  }

  .modal-content {
    width: 90%; /* Reduce modal width on mobile */
}

  h1 {
    font-size: 2.5rem; /* Adjust main heading for mobile */
  }
  .modal-text-content h2 {
    font-size: .5rem; /* Adjust modal heading for mobile */
  }

  .modal-text strong {
    font-size: 1.5rem; /* Adjust modal bold text for mobile */
  }
  /* Ensure text is readable and doesn't overflow on mobile */
  .modal-text-content .modal-text, .modal-text-content .modal-text-1 {
    font-size:0.7rem; /* Adjust text size for mobile */
    padding: 0px 10px;
    margin-top: 0px;
  }
}

/* Overlay text styling */


/* Social media icons container */
.overlay-links {
  display: flex;
  justify-content: center; /* Center the icons */
  gap: 10px; /* Add space between icons */
}

/* Follow button container */
.follow-button-container {
  display: flex;
  justify-content: center; /* Center the button */
  margin-top: 15px;
}

/* Follow button styling */
.follow-button {
  background-color: #6b6666;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.follow-button:hover {
  background-color: #b5756b;
}


@media (max-width: 767px) {
  .overlay-text {
      font-size: 15px;
      font-weight: 100;
      padding: 100px;
  }

  .overlay-links img {
      width: 40px;
      height: 40px;
  }

  .follow-button {
      padding: 8px 15px;
      font-size: 0.9rem;
  }

  .contact-form-container {
      padding: 15px;
      width: 180%; /* Keep width at 100% */
      max-width: 100vw; /* Prevent overflow */
      margin-left: -30%;
      position: relative;
  }

  .social-image {
      display: none; /* Hide the image */
  }
  
  .social-image-container {
    width: 500%; /* Keep this width */
    max-width: 100vw; /* Prevent overflow */
    margin-left: -4%; /* Shift left to align properly */
    background-color: #ffffff;
    overflow: hidden; /* Prevent overflow issues */
    position: relative; 
  }
}